



















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OParticipantZoneList: () =>
      import(
        "@/components/organisms/event/website/config/participantZone/o-participant-zone-list.vue"
      ),
  },

  setup() {
    return {};
  },
});
